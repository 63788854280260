import React, { useEffect } from 'react';
// import './App.css';
import Routes from './routes';
import { ToastContainer } from 'react-toastify'
import moment from 'moment'
import 'moment/locale/ru'
import { useNavigate } from 'react-router';
import jwtDecode from 'jwt-decode'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ruRU } from '@mui/material/locale';
import 'react-toastify/dist/ReactToastify.css';

const theme = createTheme(
  {},
  ruRU,
);

function App() {
	moment.locale('ru')
	const navigate = useNavigate()

  useEffect(() => {
    const token = localStorage.getItem('token')
    if (token) {
      const currentTime = Date.now() / 1000
      const decodedOldToken: any = jwtDecode(token)
      if (decodedOldToken?.exp < currentTime) {
        localStorage.removeItem('token')
        navigate('/auth')
      }
    } else {
      navigate('/auth')
    }
	}, [])

  return (
    <div className="App">
      <ToastContainer
        position='top-right'
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        pauseOnHover
			/>
			<ThemeProvider theme={theme}>
      	<Routes />
			</ThemeProvider>
    </div>
  );
}

export default App;
