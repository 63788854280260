
import { PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { IAdminData, IAdminState, ILoginData } from '../../types/user.types'
import AuthService from '../service/auth.service'
import { toast } from 'react-toastify'

export const logout = createAsyncThunk(
  //action type string
  'admin/logout',
  // callback function
  async () => {
    try {
			const response = await AuthService.logout()
			toast.success(`Всего хорошего!`)
      return response.data
		} catch (error: any) {
			console.log(error)
      if (error?.data?.message) {
        toast.error(`${error?.data?.message}`)
      } else {
        toast.error(`${error?.statusText}`)
      }
    }
  }
)

export const login = createAsyncThunk(
  //action type string
  'admin/login',
  // callback function
  async (data: ILoginData) => {
    try {
			const response = await AuthService.login(data)
			toast.success(`Здравствуйте!`)
      return response.data
		} catch (error: any) {
			console.log(error)
      if (error?.data?.message) {
        toast.error(`${error?.data?.message}`)
      } else {
        toast.error(`${error?.statusText}`)
      }
    }
  }
)

const initialState: IAdminState = {
  admin: null,
	status: 'default',
	isLogin: false,
}

export const adminSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<IAdminData>) => {
      state.admin = action.payload
    },
	},
	extraReducers: builder => {
		//===========login======================
		builder.addCase(login.pending, (state) => {
      state.status = "loading";
    })
    builder.addCase(login.fulfilled, (state, action) => {
			if (action.payload) {
				localStorage.setItem('token', action.payload.token)
				state.admin = action.payload.user
				state.isLogin = true
				state.status = "success";
      }
		})
		builder.addCase(login.rejected, (state) => {
      console.log("Ошибка авторизации");
			state.admin = initialState.admin
			state.isLogin = initialState.isLogin
      state.status = "error";
		})
		//===========logout======================
		builder.addCase(logout.pending, (state) => {
      state.status = "loading";
    })
    builder.addCase(logout.fulfilled, (state, action) => {
			localStorage.removeItem('token')
			state.admin = initialState.admin
			state.isLogin = initialState.isLogin
			state.status = "success";
		})
		builder.addCase(logout.rejected, (state) => {
			console.log("Ошибка завершения сессии");
			localStorage.removeItem('token')
			state.admin = initialState.admin
			state.isLogin = initialState.isLogin
      state.status = "error";
		})
	}
})

// Action creators are generated for each case reducer function
export const adminActions = { ...adminSlice.actions, logout, login }

export default adminSlice.reducer