import {FC} from 'react'
import { Wrapper } from './../auth/Style.element';
import { ErrCode, ErrMessage, ErrTitle } from './Style.element';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router';

const NotFound: FC = () => {
	const navigate = useNavigate()
	return (
		<Wrapper>
			<ErrCode>
				404
			</ErrCode>
			<ErrTitle>
				Хьюстон, у нас проблемы!
			</ErrTitle>
			<ErrMessage>
				Похоже навигатор дал сбой и мы потерялись! <br />Давайте попробуем начать с начала?
			</ErrMessage>
			<Button variant="outlined" onClick={()=> navigate('/')}>Жмякай сюда!</Button>
		</Wrapper>
	)
}

export default NotFound