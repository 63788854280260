import { createSlice } from '@reduxjs/toolkit'
import { IDevice, IDevicesAndClients } from '../../types/deviceAndClients.types'
import {getAllDevices, createDevices, updateDevices, deleteDevices, getDeviceById, getDeviceByUuid, deleteDeviceLogo} from '../actions/deviceActions'


const initialState: IDevicesAndClients = {
	devices: [],
	devicesCount: 0,
  clients: [],
	clientsCount: 0,
	status: 'default'
}

export const devicesAndClientsSlice = createSlice({
  name: 'devicesAndClients',
  initialState,
  reducers: {},
  extraReducers: builder => {
    //==============Devices==========================
    //==============get Device==========================
		builder.addCase(getAllDevices.pending, (state) => {
      state.status = "loading";
    })
    builder.addCase(getAllDevices.fulfilled, (state, action) => {
      if (action.payload) {
				state.devices = action.payload.devices
				state.devicesCount = action.payload.count
				state.status = "success";
      }
		})
		builder.addCase(getAllDevices.rejected, (state) => {
      console.log("Ошибка получения устройства");
			state.devices = initialState.devices
			state.devicesCount = initialState.devicesCount
      state.status = "error";
		})
    //==============create Device==========================
		builder.addCase(createDevices.pending, (state) => {
      state.status = "loading";
    })
    builder.addCase(createDevices.fulfilled, (state, action) => {
      if (action.payload) {
				state.devices = state?.devices?.length ? [...state?.devices, action.payload] : [action.payload]
				state.status = "success";
      }
		})
		builder.addCase(createDevices.rejected, (state) => {
      console.log("Ошибка создания устройства");
      state.status = "error";
		})
    //==============edit Device==========================
		builder.addCase(updateDevices.pending, (state) => {
      state.status = "loading";
    })
    builder.addCase(updateDevices.fulfilled, (state, action) => {
      if (action.payload) {
				state.devices = state?.devices?.map((el: IDevice) => {
					if (el?.id === action?.payload?.id) {
						return action?.payload
					} else {
						return el
					}
				}) as IDevice[]
				state.status = "success";
      }
		})
		builder.addCase(updateDevices.rejected, (state) => {
      console.log("Ошибка обновления устройства");
      state.status = "error";
		})
    //==============edit logo==========================
		builder.addCase(deleteDeviceLogo.pending, (state) => {
      state.status = "loading";
    })
    builder.addCase(deleteDeviceLogo.fulfilled, (state, action) => {
      if (action.payload) {
				state.devices = state?.devices?.map((el: IDevice) => {
					if (el?.id === action?.payload?.id) {
						return action?.payload
					} else {
						return el
					}
				}) as IDevice[]
				state.status = "success";
      }
		})
		builder.addCase(deleteDeviceLogo.rejected, (state) => {
      console.log("Ошибка обновления устройства");
      state.status = "error";
		})
    //==============delete Device==========================
		builder.addCase(deleteDevices.pending, (state) => {
      state.status = "loading";
    })
    builder.addCase(deleteDevices.fulfilled, (state, action) => {
      if (action.payload) {
				state.devices = state?.devices?.filter(el=> el.id !== action.payload)
				state.status = "success";
      }
		})
		builder.addCase(deleteDevices.rejected, (state) => {
      console.log("Ошибка удаления устройства");
      state.status = "error";
		})
  },
})

// Action creators are generated for each case reducer function
export const devicesAndClientsActions: any = {
  ...devicesAndClientsSlice.actions,
	getAllDevices,
	createDevices,
	updateDevices,
	deleteDevices,
	getDeviceById,
	getDeviceByUuid,
	deleteDeviceLogo
}

export default devicesAndClientsSlice.reducer
