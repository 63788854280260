import { adminActions } from './reducers/admin'
import { materialsActions } from './reducers/materials'
import { devicesAndClientsActions } from './reducers/devicesAndClients'

const actions = {
  ...adminActions,
  ...materialsActions,
  ...devicesAndClientsActions,
}

export default actions