import { createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { IQuery } from '../../types/query'
import DeviceService from '../service/device.service'
import { IDevice } from '../../types/deviceAndClients.types'

export const getAllDevices = createAsyncThunk(
  //action type string
  'devices/getAllDevices',
  // callback function
  async (query?: IQuery) => {
    try {
			const response = await DeviceService.getAllDevices(query)
      return response.data
		} catch (error: any) {
			console.log(error)
      if (error?.data?.message) {
        toast.error(`${error?.data?.message}`)
      } else {
        toast.error(`${error?.statusText}`)
      }
    }
  }
)

export const createDevices = createAsyncThunk(
  //action type string
  'devices/createDevices',
  // callback function
  async (data: IDevice) => {
    try {
			const response = await DeviceService.createDevices(data)
			toast.success(`Устройство создано`)
      return response.data
		} catch (error: any) {
			console.log(error)
      if (error?.data?.message) {
        toast.error(`${error?.data?.message}`)
      } else {
        toast.error(`${error?.statusText}`)
      }
    }
  }
)

export const updateDevices = createAsyncThunk(
  //action type string
  'devices/updateDevices',
  // callback function
  async (data: IDevice) => {
    try {
			const response = await DeviceService.updateDevices(data)
			toast.success(`Устройство отредактировано`)
      return response.data
		} catch (error: any) {
			console.log(error)
      if (error?.data?.message) {
        toast.error(`${error?.data?.message}`)
      } else {
        toast.error(`${error?.statusText}`)
      }
    }
  }
)

export const deleteDevices = createAsyncThunk(
  //action type string
  'devices/deleteDevices',
  // callback function
  async (id: number) => {
    try {
			await DeviceService.deleteDevices(id)
			toast.success(`Устройство удалено`)
      return id
		} catch (error: any) {
			console.log(error)
      if (error?.data?.message) {
        toast.error(`${error?.data?.message}`)
      } else {
        toast.error(`${error?.statusText}`)
      }
    }
  }
)

export const getDeviceById = createAsyncThunk(
  //action type string
  'devices/getDeviceById',
  // callback function
  async (id: number) => {
    try {
			const response = await DeviceService.getDeviceById(id)
      return response.data
		} catch (error: any) {
			console.log(error)
      if (error?.data?.message) {
        toast.error(`${error?.data?.message}`)
      } else {
        toast.error(`${error?.statusText}`)
      }
    }
  }
)

export const getDeviceByUuid = createAsyncThunk(
  //action type string
  'devices/getDeviceByUuid',
  // callback function
  async (uuid: number) => {
    try {
			const response = await DeviceService.getDeviceByUuid(uuid)
      return response.data
		} catch (error: any) {
			console.log(error)
      if (error?.data?.message) {
        toast.error(`${error?.data?.message}`)
      } else {
        toast.error(`${error?.statusText}`)
      }
    }
  }
)


export const deleteDeviceLogo = createAsyncThunk(
  //action type string
  'devices/deleteDeviceLogo',
  // callback function
  async (data: {deviceId: number, file: string}) => {
    try {
			const response = await DeviceService.deleteDeviceLogo(data)
			toast.success(`Файл удалён`)
      return response.data
		} catch (error: any) {
			console.log(error)
      if (error?.data?.message) {
        toast.error(`${error?.data?.message}`)
      } else {
        toast.error(`${error?.statusText}`)
      }
    }
  }
)