import { FC, ReactNode } from 'react'

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import TvIcon from '@mui/icons-material/Tv';
import BarChartIcon from '@mui/icons-material/BarChart';
import { useNavigate } from 'react-router';
import ScoreIcon from '@mui/icons-material/Score';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import LogoutIcon from '@mui/icons-material/Logout';
import { useActions } from '../hooks/useActions';

export const drawerWidth = 240;

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

interface IMenuItem {
	title: string
	link: string
	icon: ReactNode
}

const menuItems: IMenuItem[] = [
	{title: 'Статистика', link: '/', icon: <BarChartIcon />},
	{title: 'Устройства', link: '/devices', icon: <TvIcon />},
]

const materialItems: IMenuItem[] = [
	{title: 'Книги', link: '/books', icon: <AutoStoriesIcon />},
	{title: 'Видео', link: '/video', icon: <OndemandVideoIcon />},
	{title: 'Презентации', link: '/presentations', icon: <ScoreIcon />},
]

const SideBar: FC<Props> = ({window}) => {
	const container = window !== undefined ? () => window().document.body : undefined;
	const navigate = useNavigate()
	const {logout} = useActions()

	const handleExit = (): void => {
		logout()
		navigate('/auth')
	}

	const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        {menuItems?.map((el: IMenuItem) => (
          <ListItem key={el.title} disablePadding onClick={()=> navigate(el.link)}>
            <ListItemButton>
              <ListItemIcon>
                {el?.icon}
              </ListItemIcon>
              <ListItemText primary={el.title} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {materialItems?.map((el: IMenuItem) => (
          <ListItem key={el.title} disablePadding onClick={()=> navigate(el.link)}>
            <ListItemButton>
              <ListItemIcon>
                {el?.icon}
              </ListItemIcon>
              <ListItemText primary={el.title} />
            </ListItemButton>
          </ListItem>
        ))}
			</List>
			<Divider />
				<ListItem disablePadding onClick={handleExit}>
            <ListItemButton>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary='Выход' />
            </ListItemButton>
          </ListItem>
    </div>
  );

	return (
		<>
			<Drawer
				container={container}
				variant="temporary"
				open={true}
				// onClose={handleDrawerToggle}
				ModalProps={{
					keepMounted: true, // Better open performance on mobile.
				}}
				sx={{
					display: { xs: 'block', sm: 'none' },
					'& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
				}}
			>
				{drawer}
			</Drawer>
			<Drawer
				variant="permanent"
				sx={{
					display: { xs: 'none', sm: 'block' },
					'& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
				}}
				open
			>
				{drawer}
			</Drawer>
		</>
	)
}

export default SideBar