import { useRoutes } from 'react-router-dom'

import AuthRoutes from './AuthRoutes'
import MainRoutes from './MainRoutes'
import NotFound from './../pages/NotFound/index';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
	return useRoutes( [
		AuthRoutes,
		MainRoutes,
		{
			path: '*',
			element: <NotFound />,
		}
	] )
}
