import { createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import BookService from '../service/books.service'
import VideoService from '../service/video.service'
import InfoService from '../service/info.service'
import { IBook, IBookContent, IMaterialToDevice, IInfo, IVideo, IVideoContent } from '../../types/material.types'
import { IQuery } from '../../types/query'

export const getAllBooks = createAsyncThunk(
  //action type string
  'books/getAllBooks',
  // callback function
  async (query?: IQuery) => {
    try {
			const response = await BookService.getAllBooks(query)
      return response.data
		} catch (error: any) {
			console.log(error)
      if (error?.data?.message) {
        toast.error(`${error?.data?.message}`)
      } else {
        toast.error(`${error?.statusText}`)
      }
    }
  }
)

export const createBook = createAsyncThunk(
  //action type string
  'books/createBook',
  // callback function
  async (book: IBook) => {
    try {
			const response = await BookService.createBook(book)
			toast.success(`Книга успешно создана`)
      return response.data
		} catch (error: any) {
			console.log(error)
      if (error?.data?.message) {
        toast.error(`${error?.data?.message}`)
      } else {
        toast.error(`${error?.statusText}`)
      }
    }
  }
)

export const deleteBook = createAsyncThunk(
  //action type string
  'books/deleteBook',
  // callback function
  async (id: number) => {
    try {
			await BookService.deleteBook(id)
			toast.success(`Книга успешно удалена`)
      return id
		} catch (error: any) {
			console.log(error)
      if (error?.data?.message) {
        toast.error(`${error?.data?.message}`)
      } else {
        toast.error(`${error?.statusText}`)
      }
    }
  }
)

export const editBook = createAsyncThunk(
  //action type string
  'books/editBook',
  // callback function
  async (book: IBook) => {
    try {
			const response = await BookService.editBook(book)
			toast.success(`Книга успешно отредактирована`)
      return response?.data
		} catch (error: any) {
			console.log(error)
      if (error?.data?.message) {
        toast.error(`${error?.data?.message}`)
      } else {
        toast.error(`${error?.statusText}`)
      }
    }
  }
)

export const createBookContent = createAsyncThunk(
  //action type string
  'books/createBookContent',
  // callback function
  async (content: IBookContent) => {
    try {
			const response = await BookService.createBookContent(content)
			toast.success(`Содержание добавлено`)
      return response?.data
		} catch (error: any) {
			console.log(error)
      if (error?.data?.message) {
        toast.error(`${error?.data?.message}`)
      } else {
        toast.error(`${error?.statusText}`)
      }
    }
  }
)

export const deleteBookContent = createAsyncThunk(
  //action type string
  'books/deleteBookContent',
  // callback function
  async (id: number) => {
    try {
			const response = await BookService.deleteBookContent(id)
			toast.success(`Оглавление успешно удалено`)
      return response.data
		} catch (error: any) {
			console.log(error)
      if (error?.data?.message) {
        toast.error(`${error?.data?.message}`)
      } else {
        toast.error(`${error?.statusText}`)
      }
    }
  }
)

export const deleteBookFile = createAsyncThunk(
  //action type string
  'books/deleteBookFile',
  // callback function
  async (data: {bookId: number, file: string}) => {
    try {
			const response = await BookService.deleteBookFile(data)
			toast.success(`Файл удалён`)
      return response.data
		} catch (error: any) {
			console.log(error)
      if (error?.data?.message) {
        toast.error(`${error?.data?.message}`)
      } else {
        toast.error(`${error?.statusText}`)
      }
    }
  }
)

export const addBookToDevice = createAsyncThunk(
  //action type string
  'books/addBookToDevice',
  // callback function
  async (data: IMaterialToDevice) => {
    try {
			const response = await BookService.addBookToDevice(data)
			toast.success(`Устройство добавлено`)
      return response.data
		} catch (error: any) {
			console.log(error)
      if (error?.data?.message) {
        toast.error(`${error?.data?.message}`)
      } else {
        toast.error(`${error?.statusText}`)
      }
    }
  }
)

//===============================================
//==================VIDEO=====================
//===============================================

export const getAllVideo = createAsyncThunk(
  //action type string
  'video/getAllVideo',
  // callback function
  async (query?: IQuery) => {
    try {
			const response = await VideoService.getAllVideo(query)
      return response.data
		} catch (error: any) {
			console.log(error)
      if (error?.data?.message) {
        toast.error(`${error?.data?.message}`)
      } else {
        toast.error(`${error?.statusText}`)
      }
    }
  }
)

export const createVideo = createAsyncThunk(
  //action type string
  'video/createVideo',
  // callback function
  async (video: IVideo) => {
    try {
			const response = await VideoService.createVideo(video)
			toast.success(`Видео успешно добавлено`)
      return response.data
		} catch (error: any) {
			console.log(error)
      if (error?.data?.message) {
        toast.error(`${error?.data?.message}`)
      } else {
        toast.error(`${error?.statusText}`)
      }
    }
  }
)

export const deleteVideo = createAsyncThunk(
  //action type string
  'video/deleteVideo',
  // callback function
  async (id: number) => {
    try {
			await VideoService.deleteVideo(id)
			toast.success(`Видео успешно удалено`)
      return id
		} catch (error: any) {
			console.log(error)
      if (error?.data?.message) {
        toast.error(`${error?.data?.message}`)
      } else {
        toast.error(`${error?.statusText}`)
      }
    }
  }
)

export const editVideo = createAsyncThunk(
  //action type string
  'video/editVideo',
  // callback function
  async (video: IVideo) => {
    try {
			const response = await VideoService.editVideo(video)
			toast.success(`Видео успешно отредактировано`)
      return response?.data
		} catch (error: any) {
			console.log(error)
      if (error?.data?.message) {
        toast.error(`${error?.data?.message}`)
      } else {
        toast.error(`${error?.statusText}`)
      }
    }
  }
)

export const createVideoContent = createAsyncThunk(
  //action type string
  'video/createVideoContent',
  // callback function
  async (content: IVideoContent) => {
    try {
			const response = await VideoService.createVideoContent(content)
			toast.success(`Содержание добавлено`)
      return response?.data
		} catch (error: any) {
			console.log(error)
      if (error?.data?.message) {
        toast.error(`${error?.data?.message}`)
      } else {
        toast.error(`${error?.statusText}`)
      }
    }
  }
)

export const deleteVideoContent = createAsyncThunk(
  //action type string
  'video/deleteVideoContent',
  // callback function
  async (id: number) => {
    try {
			const response = await VideoService.deleteVideoContent(id)
			toast.success(`Оглавление успешно удалено`)
      return response.data
		} catch (error: any) {
			console.log(error)
      if (error?.data?.message) {
        toast.error(`${error?.data?.message}`)
      } else {
        toast.error(`${error?.statusText}`)
      }
    }
  }
)

export const deleteVideoFile = createAsyncThunk(
  //action type string
  'video/deleteVideoFile',
  // callback function
  async (data: {videoId: number, file: string}) => {
    try {
			const response = await VideoService.deleteVideoFile(data)
			toast.success(`Файл удалён`)
      return response.data
		} catch (error: any) {
			console.log(error)
      if (error?.data?.message) {
        toast.error(`${error?.data?.message}`)
      } else {
        toast.error(`${error?.statusText}`)
      }
    }
  }
)


export const addVideoToDevice = createAsyncThunk(
  //action type string
  'video/addVideoToDevice',
  // callback function
  async (data: IMaterialToDevice) => {
    try {
			const response = await VideoService.addVideoToDevice(data)
			toast.success(`Устройство добавлено`)
      return response.data
		} catch (error: any) {
			console.log(error)
      if (error?.data?.message) {
        toast.error(`${error?.data?.message}`)
      } else {
        toast.error(`${error?.statusText}`)
      }
    }
  }
)

//===============================================
//==================Presintations=====================
//===============================================

export const getAllInfo = createAsyncThunk(
  //action type string
  'info/getAllInfo',
  // callback function
  async (query?: IQuery) => {
    try {
			const response = await InfoService.getAllInfo(query)
      return response.data
		} catch (error: any) {
			console.log(error)
      if (error?.data?.message) {
        toast.error(`${error?.data?.message}`)
      } else {
        toast.error(`${error?.statusText}`)
      }
    }
  }
)

export const createInfo = createAsyncThunk(
  //action type string
  'info/createInfo',
  // callback function
  async (info: IInfo) => {
    try {
			const response = await InfoService.createInfo(info)
			toast.success(`Презентация успешно добавлена`)
      return response.data
		} catch (error: any) {
			console.log(error)
      if (error?.data?.message) {
        toast.error(`${error?.data?.message}`)
      } else {
        toast.error(`${error?.statusText}`)
      }
    }
  }
)

export const deleteInfo = createAsyncThunk(
  //action type string
  'info/deleteInfo',
  // callback function
  async (id: number) => {
    try {
			await InfoService.deleteInfo(id)
			toast.success(`Презентация успешно удалена`)
      return id
		} catch (error: any) {
			console.log(error)
      if (error?.data?.message) {
        toast.error(`${error?.data?.message}`)
      } else {
        toast.error(`${error?.statusText}`)
      }
    }
  }
)

export const editInfo = createAsyncThunk(
  //action type string
  'info/editInfo',
  // callback function
  async (info: IInfo) => {
    try {
			const response = await InfoService.editInfo(info)
			toast.success(`Презентация успешно отредактирована`)
      return response?.data
		} catch (error: any) {
			console.log(error)
      if (error?.data?.message) {
        toast.error(`${error?.data?.message}`)
      } else {
        toast.error(`${error?.statusText}`)
      }
    }
  }
)

export const createInfoContent = createAsyncThunk(
  //action type string
  'info/createInfoContent',
  // callback function
  async (content: IVideoContent) => {
    try {
			const response = await InfoService.createInfoContent(content)
			toast.success(`Содержание добавлено`)
      return response?.data
		} catch (error: any) {
			console.log(error)
      if (error?.data?.message) {
        toast.error(`${error?.data?.message}`)
      } else {
        toast.error(`${error?.statusText}`)
      }
    }
  }
)

export const deleteInfoContent = createAsyncThunk(
  //action type string
  'info/deleteInfoContent',
  // callback function
  async (id: number) => {
    try {
			const response = await InfoService.deleteInfoContent(id)
			toast.success(`Оглавление успешно удалено`)
      return response.data
		} catch (error: any) {
			console.log(error)
      if (error?.data?.message) {
        toast.error(`${error?.data?.message}`)
      } else {
        toast.error(`${error?.statusText}`)
      }
    }
  }
)

export const deleteInfoFile = createAsyncThunk(
  //action type string
  'info/deleteInfoFile',
  // callback function
  async (data: {infoId: number, file: string}) => {
    try {
			const response = await InfoService.deleteInfoFile(data)
			toast.success(`Файл удалён`)
      return response.data
		} catch (error: any) {
			console.log(error)
      if (error?.data?.message) {
        toast.error(`${error?.data?.message}`)
      } else {
        toast.error(`${error?.statusText}`)
      }
    }
  }
)

export const addInfoToDevice = createAsyncThunk(
  //action type string
  'info/addInfoToDevice',
  // callback function
  async (data: IMaterialToDevice) => {
    try {
			const response = await InfoService.addInfoToDevice(data)
			toast.success(`Устройство добавлено`)
      return response.data
		} catch (error: any) {
			console.log(error)
      if (error?.data?.message) {
        toast.error(`${error?.data?.message}`)
      } else {
        toast.error(`${error?.statusText}`)
      }
    }
  }
)