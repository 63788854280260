import { combineReducers } from 'redux'
import adminReducer from './admin'
import materialsReducer from './materials'
import devicesAndClientsReducer from './devicesAndClients'


const rootReducer = combineReducers({
	admin: adminReducer,
	materials: materialsReducer,
	devicesAndClients: devicesAndClientsReducer,

})

export default rootReducer
