
import { ILoginData, ILoginResponse } from '../../types/user.types'
import { $api } from '../api'


export default class AuthService {
  static async login(data: ILoginData): Promise<{data: ILoginResponse}> {
    return $api.post('/auth/login', data)
  }
  static async logout(): Promise<{data: ILoginResponse}> {
    return $api.post('/auth/logout')
  }
}
