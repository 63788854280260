
import { IInfo, IInfoContent, IMaterialToDevice } from '../../types/material.types'
import { IQuery } from '../../types/query'
import { $api } from '../api'


export default class InfoService {
	//=============INFO=======================
	static async getAllInfo(query?: IQuery): Promise<{ data: { data: IInfo[]; count: number } }> {
		const params = `?limit=${query?.limit ? query.limit : 0}&offset=${query?.offset ? query.offset : 0}${query?.search ? `&search=${query?.search}`: ''}`
    return $api.get(`/info/${params}`)
  }
  static async createInfo(data: IInfo): Promise<{data: IInfo}> {
    return $api.post('/info', data, {headers: { "Content-Type": "multipart/form-data" }})
  }
  static async deleteInfo(id: number): Promise<{data: any}> {
    return $api.delete(`/info/${id}`)
  }
  static async editInfo(data: IInfo): Promise<{data: IInfo}> {
    return $api.put(`/info/`, data, {headers: { "Content-Type": "multipart/form-data" }})
	}
	//===============Contents=====================
  static async createInfoContent(data: IInfoContent): Promise<{data: IInfo}> {
    return $api.post(`/info/content`, data)
  }
  static async deleteInfoContent(id: number): Promise<{data: IInfo}> {
    return $api.delete(`/info/content/${id}`)
	}
	//==============file=====================
	static async deleteInfoFile(data: {infoId: number, file: string}): Promise<{data: IInfo}> {
    return $api.delete(`/info/file/${data.infoId}`, {data})
	}
	//==============Device=====================
	static async addInfoToDevice(data: IMaterialToDevice): Promise<{data: IInfo}> {
    return $api.post(`/info/device`, data)
	}
}
