import { lazy } from 'react'

import Loadable from './../components/Loadable/index';

import Layout from './../layout/index';

// import Statistics from '../pages/statistics';

const Statistics = Loadable(lazy(() => import('../pages/statistics/')))
const Devices = Loadable(lazy(() => import('../pages/devices/')))
const Books = Loadable(lazy(() => import('../pages/books/')))
const Video = Loadable(lazy(() => import('../pages/video/')))
const Presentations = Loadable(lazy(() => import('../pages/presentations/')))


const MainRoutes = {
  path: '/',
  element: <Layout />,
  children: [
    {
      index: true,
      element: <Statistics />,
    },
    {
      path: '/devices',
      element: <Devices />,
    },
    {
      path: '/books',
      element: <Books />,
    },
    {
      path: '/video',
      element: <Video />,
    },
    {
      path: '/presentations',
      element: <Presentations />,
    },

  ],
}

export default MainRoutes
