import styled from 'styled-components'

export const Wrapper = styled.div`
	position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
	text-align: center;
`

export const ErrCode = styled.div`
	font-size: 100px;
	text-align: center;
`
export const ErrTitle = styled.div`
	font-size: 40px;
	text-align: center;
`

export const ErrMessage = styled.div`
	padding: 10px;
	font-size: 25px;
	text-align: center;
`
