import { lazy } from 'react'
import Loadable from '../components/Loadable'

const Auth = Loadable(lazy(() => import('../pages/auth')))


const AuthRoutes = {
  path: '/auth',
  element: <Auth />,
}

export default AuthRoutes
