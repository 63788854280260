
import { IDevice } from '../../types/deviceAndClients.types';
import { IBook, IBookContent, IMaterialToDevice } from '../../types/material.types'
import { IQuery } from '../../types/query'
import { $api } from '../api'


export default class BookService {
	//=============BOOKS=======================
	static async getAllBooks(query?: IQuery): Promise<{ data: { data: IBook[]; count: number } }> {
		const params = `?limit=${query?.limit ? query.limit : 0}&offset=${query?.offset ? query.offset : 0}${query?.search ? `&search=${query?.search}`: ''}`
    return $api.get(`/books/${params}`)
  }
  static async createBook(data: IBook): Promise<{data: IBook}> {
    return $api.post('/books', data, {headers: { "Content-Type": "multipart/form-data" }})
  }
  static async deleteBook(id: number): Promise<{data: any}> {
    return $api.delete(`/books/${id}`)
  }
  static async editBook(data: IBook): Promise<{data: IBook}> {
    return $api.put(`/books/`, data, {headers: { "Content-Type": "multipart/form-data" }})
	}
	//===============Contents=====================
  static async createBookContent(data: IBookContent): Promise<{data: IBook}> {
    return $api.post(`/books/content`, data)
  }
  static async deleteBookContent(id: number): Promise<{data: IBook}> {
    return $api.delete(`/books/content/${id}`)
	}
	//==============file=====================
	static async deleteBookFile(data: {bookId: number, file: string}): Promise<{data: IBook}> {
    return $api.delete(`/books/file/${data.bookId}`, {data})
	}
	//==============Device=====================
	static async addBookToDevice(data: IMaterialToDevice): Promise<{data: IBook}> {
    return $api.post(`/books/device`, data)
	}
}
