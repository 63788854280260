import { createSlice } from '@reduxjs/toolkit'
import { IBook, IInfo, IMaterialsState, IVideo } from '../../types/material.types'
import {
	getAllBooks, createBook, deleteBook, editBook, createBookContent, deleteBookContent, deleteBookFile, addBookToDevice,
	getAllVideo, createVideo, deleteVideo, editVideo, createVideoContent, deleteVideoContent, deleteVideoFile, addVideoToDevice,
	getAllInfo, createInfo, deleteInfo, editInfo, createInfoContent, deleteInfoContent, deleteInfoFile, addInfoToDevice
} from '../actions/materialsActions'


const initialState: IMaterialsState = {
	books: [],
	booksCount: 0,
  videos: [],
	videosCount: 0,
	presentations: [],
	presentationsCount: 0,
	status: 'default',
}

export const materialsSlice = createSlice({
  name: 'materials',
  initialState,
  reducers: {},
  extraReducers: builder => {
    //==============Books==========================
		//==============get books=====================
		builder.addCase(getAllBooks.pending, (state) => {
      state.status = "loading";
    })
    builder.addCase(getAllBooks.fulfilled, (state, action) => {
      if (action.payload) {
        state.books = action.payload.data
        state.booksCount = action.payload.count
			}
			state.status = 'success';
		})
		builder.addCase(getAllBooks.rejected, (state) => {
      console.log("Ошибка получения книг");
			state.books = initialState.books
			state.booksCount = initialState.booksCount
      state.status = "error";
		})
		//==============create book=====================
		builder.addCase(createBook.pending, (state) => {
      state.status = "loading";
    })
    builder.addCase(createBook.fulfilled, (state, action) => {
      if (action.payload) {
        state.books = state?.books?.length ? [...state?.books, action.payload] : [action.payload]
      }
			state.status = 'success';
		})
		builder.addCase(createBook.rejected, (state) => {
      console.log("Ошибка создания книги");
      // state.books = initialState.books
      state.status = "error";
		})
		//==============delete book=====================
		builder.addCase(deleteBook.pending, (state) => {
      state.status = "loading";
    })
    builder.addCase(deleteBook.fulfilled, (state, action) => {
      if (action.payload) {
        state.books = state?.books?.filter(el=> el.id !== action.payload)
			}
			state.status = 'success';
		})
		builder.addCase(deleteBook.rejected, (state) => {
      console.log("Ошибка удаления книги");
      // state.books = initialState.books
      state.status = "error";
		})
		//==============edit book=====================
		builder.addCase(editBook.pending, (state) => {
      state.status = "loading";
    })
    builder.addCase(editBook.fulfilled, (state, action) => {
      if (action.payload) {
				state.books = state?.books?.map((book: IBook) => {
					if (book?.id === action?.payload?.id) {
						return action?.payload
					} else {
						return book
					}
				}) as IBook[]
			}
			state.status = 'success';
		})
		builder.addCase(editBook.rejected, (state) => {
      console.log("Ошибка редактирования книги");
      // state.books = initialState.books
      state.status = "error";
		})
		//==============delete file=====================
		builder.addCase(deleteBookFile.pending, (state) => {
      state.status = "loading";
    })
    builder.addCase(deleteBookFile.fulfilled, (state, action) => {
      if (action.payload) {
				state.books = state?.books?.map((book: IBook) => {
					if (book?.id === action?.payload?.id) {
						return action?.payload
					} else {
						return book
					}
				}) as IBook[]
			}
			state.status = 'success';
		})
		builder.addCase(deleteBookFile.rejected, (state) => {
      console.log("Ошибка удаления файла");
      // state.books = initialState.books
      state.status = "error";
		})
		//==============create content=====================
		builder.addCase(createBookContent.pending, (state) => {
      state.status = "loading";
    })
    builder.addCase(createBookContent.fulfilled, (state, action) => {
      if (action.payload) {
				state.books = state?.books?.map((book: IBook) => {
					if (book?.id === action?.payload?.id) {
						return action?.payload
					} else {
						return book
					}
				}) as IBook[]
			}
			state.status = 'success';
		})
		builder.addCase(createBookContent.rejected, (state) => {
      console.log("Ошибка добавления контента");
      // state.books = initialState.books
      state.status = "error";
		})
		//==============delete content=====================
		builder.addCase(deleteBookContent.pending, (state) => {
      state.status = "loading";
    })
    builder.addCase(deleteBookContent.fulfilled, (state, action) => {
      if (action.payload) {
				state.books = state?.books?.map((book: IBook) => {
					if (book?.id === action?.payload?.id) {
						return action?.payload
					} else {
						return book
					}
				}) as IBook[]
			}
			state.status = 'success';
		})
		builder.addCase(deleteBookContent.rejected, (state) => {
      console.log("Ошибка удаления контента");
      // state.books = initialState.books
      state.status = "error";
		})
		//==============add device=====================
		builder.addCase(addBookToDevice.pending, (state) => {
      state.status = "loading";
    })
    builder.addCase(addBookToDevice.fulfilled, (state, action) => {
      if (action.payload) {
				state.books = state?.books?.map((book: IBook) => {
					if (book?.id === action?.payload?.id) {
						return action?.payload
					} else {
						return book
					}
				}) as IBook[]
			}
			state.status = 'success';
		})
		builder.addCase(addBookToDevice.rejected, (state) => {
      console.log("Ошибка добавления устройства");
      // state.books = initialState.books
      state.status = "error";
		})
//===============================================
//==================VIDEO=====================
//===============================================
		//==============get info=====================
		builder.addCase(getAllVideo.pending, (state) => {
			state.status = "loading";
		})
		builder.addCase(getAllVideo.fulfilled, (state, action) => {
			if (action.payload) {
				state.videos = action.payload.data
				state.videosCount = action.payload.count
			}
			state.status = 'success';
		})
		builder.addCase(getAllVideo.rejected, (state) => {
			console.log("Ошибка получения видео");
			state.videos = initialState.videos
			state.videosCount = initialState.videosCount
			state.status = "error";
		})
		//==============create video=====================
		builder.addCase(createVideo.pending, (state) => {
			state.status = "loading";
		})
		builder.addCase(createVideo.fulfilled, (state, action) => {
			if (action.payload) {
				state.videos = state?.videos?.length ? [...state?.videos, action.payload] : [action.payload]
			}
			state.status = 'success';
		})
		builder.addCase(createVideo.rejected, (state) => {
			console.log("Ошибка создания видео");
			// state.books = initialState.books
			state.status = "error";
		})
		//==============delete video=====================
		builder.addCase(deleteVideo.pending, (state) => {
			state.status = "loading";
		})
		builder.addCase(deleteVideo.fulfilled, (state, action) => {
			if (action.payload) {
				state.videos = state?.videos?.filter(el=> el.id !== action.payload)
			}
			state.status = 'success';
		})
		builder.addCase(deleteVideo.rejected, (state) => {
			console.log("Ошибка удаления видео");
			// state.books = initialState.books
			state.status = "error";
		})
		//==============edit video=====================
		builder.addCase(editVideo.pending, (state) => {
			state.status = "loading";
		})
		builder.addCase(editVideo.fulfilled, (state, action) => {
			if (action.payload) {
				state.videos = state?.videos?.map((el: IVideo) => {
					if (el?.id === action?.payload?.id) {
						return action?.payload
					} else {
						return el
					}
				}) as IVideo[]
			}
			state.status = 'success';
		})
		builder.addCase(editVideo.rejected, (state) => {
			console.log("Ошибка редактирования видео");
			// state.books = initialState.books
			state.status = "error";
		})
		//==============delete video file=====================
		builder.addCase(deleteVideoFile.pending, (state) => {
			state.status = "loading";
		})
		builder.addCase(deleteVideoFile.fulfilled, (state, action) => {
			if (action.payload) {
				state.videos = state?.videos?.map((el: IVideo) => {
					if (el?.id === action?.payload?.id) {
						return action?.payload
					} else {
						return el
					}
				}) as IVideo[]
			}
			state.status = 'success';
		})
		builder.addCase(deleteVideoFile.rejected, (state) => {
			console.log("Ошибка удаления файла");
			// state.books = initialState.books
			state.status = "error";
		})
		//==============create video content=====================
		builder.addCase(createVideoContent.pending, (state) => {
			state.status = "loading";
		})
		builder.addCase(createVideoContent.fulfilled, (state, action) => {
			if (action.payload) {
				state.videos = state?.videos?.map((el: IVideo) => {
					if (el?.id === action?.payload?.id) {
						return action?.payload
					} else {
						return el
					}
				}) as IVideo[]
			}
			state.status = 'success';
		})
		builder.addCase(createVideoContent.rejected, (state) => {
			console.log("Ошибка добавления контента");
			// state.books = initialState.books
			state.status = "error";
		})
		//==============delete video content=====================
		builder.addCase(deleteVideoContent.pending, (state) => {
			state.status = "loading";
		})
		builder.addCase(deleteVideoContent.fulfilled, (state, action) => {
			if (action.payload) {
				state.videos = state?.videos?.map((el: IVideo) => {
					if (el?.id === action?.payload?.id) {
						return action?.payload
					} else {
						return el
					}
				}) as IVideo[]
			}
			state.status = 'success';
		})
		builder.addCase(deleteVideoContent.rejected, (state) => {
			console.log("Ошибка удаления контента");
			// state.books = initialState.books
			state.status = "error";
		})
		//==============add device=====================
		builder.addCase(addVideoToDevice.pending, (state) => {
      state.status = "loading";
    })
    builder.addCase(addVideoToDevice.fulfilled, (state, action) => {
      if (action.payload) {
				state.videos = state?.videos?.map((book: IVideo) => {
					if (book?.id === action?.payload?.id) {
						return action?.payload
					} else {
						return book
					}
				}) as IVideo[]
			}
			state.status = 'success';
		})
		builder.addCase(addVideoToDevice.rejected, (state) => {
      console.log("Ошибка добавления устройства");
      // state.books = initialState.books
      state.status = "error";
		})
//===============================================
//==================VIDEO=====================
//===============================================
		//==============get video=====================
		builder.addCase(getAllInfo.pending, (state) => {
			state.status = "loading";
		})
		builder.addCase(getAllInfo.fulfilled, (state, action) => {
			if (action.payload) {
				state.presentations = action.payload.data
				state.presentationsCount = action.payload.count
			}
			state.status = 'success';
		})
		builder.addCase(getAllInfo.rejected, (state) => {
			console.log("Ошибка получения презентации");
			state.presentations = initialState.presentations
			state.presentationsCount = initialState.presentationsCount
			state.status = "error";
		})
		//==============create info=====================
		builder.addCase(createInfo.pending, (state) => {
			state.status = "loading";
		})
		builder.addCase(createInfo.fulfilled, (state, action) => {
			if (action.payload) {
				state.presentations = state?.presentations?.length ? [...state?.presentations, action.payload] : [action.payload]
			}
			state.status = 'success';
		})
		builder.addCase(createInfo.rejected, (state) => {
			console.log("Ошибка создания презентации");
			// state.books = initialState.books
			state.status = "error";
		})
		//==============delete info=====================
		builder.addCase(deleteInfo.pending, (state) => {
			state.status = "loading";
		})
		builder.addCase(deleteInfo.fulfilled, (state, action) => {
			if (action.payload) {
				state.presentations = state?.presentations?.filter(el=> el.id !== action.payload)
			}
			state.status = 'success';
		})
		builder.addCase(deleteInfo.rejected, (state) => {
			console.log("Ошибка удаления презентации");
			// state.books = initialState.books
			state.status = "error";
		})
		//==============edit info=====================
		builder.addCase(editInfo.pending, (state) => {
			state.status = "loading";
		})
		builder.addCase(editInfo.fulfilled, (state, action) => {
			if (action.payload) {
				state.presentations = state?.presentations?.map((el: IInfo) => {
					if (el?.id === action?.payload?.id) {
						return action?.payload
					} else {
						return el
					}
				}) as IInfo[]
			}
			state.status = 'success';
		})
		builder.addCase(editInfo.rejected, (state) => {
			console.log("Ошибка редактирования презентации");
			// state.books = initialState.books
			state.status = "error";
		})
		//==============delete video info=====================
		builder.addCase(deleteInfoFile.pending, (state) => {
			state.status = "loading";
		})
		builder.addCase(deleteInfoFile.fulfilled, (state, action) => {
			if (action.payload) {
				state.presentations = state?.presentations?.map((el: IInfo) => {
					if (el?.id === action?.payload?.id) {
						return action?.payload
					} else {
						return el
					}
				}) as IInfo[]
			}
			state.status = 'success';
		})
		builder.addCase(deleteInfoFile.rejected, (state) => {
			console.log("Ошибка удаления файла");
			// state.books = initialState.books
			state.status = "error";
		})
		//==============create info content=====================
		builder.addCase(createInfoContent.pending, (state) => {
			state.status = "loading";
		})
		builder.addCase(createInfoContent.fulfilled, (state, action) => {
			if (action.payload) {
				state.presentations = state?.presentations?.map((el: IInfo) => {
					if (el?.id === action?.payload?.id) {
						return action?.payload
					} else {
						return el
					}
				}) as IInfo[]
			}
			state.status = 'success';
		})
		builder.addCase(createInfoContent.rejected, (state) => {
			console.log("Ошибка добавления контента");
			// state.books = initialState.books
			state.status = "error";
		})
		//==============delete info content=====================
		builder.addCase(deleteInfoContent.pending, (state) => {
			state.status = "loading";
		})
		builder.addCase(deleteInfoContent.fulfilled, (state, action) => {
			if (action.payload) {
				state.presentations = state?.presentations?.map((el: IInfo) => {
					if (el?.id === action?.payload?.id) {
						return action?.payload
					} else {
						return el
					}
				}) as IInfo[]
			}
			state.status = 'success';
		})
		builder.addCase(deleteInfoContent.rejected, (state) => {
			console.log("Ошибка удаления контента");
			// state.books = initialState.books
			state.status = "error";
		})
		//==============add device=====================
		builder.addCase(addInfoToDevice.pending, (state) => {
      state.status = "loading";
    })
    builder.addCase(addInfoToDevice.fulfilled, (state, action) => {
      if (action.payload) {
				state.presentations = state?.presentations?.map((book: IInfo) => {
					if (book?.id === action?.payload?.id) {
						return action?.payload
					} else {
						return book
					}
				}) as IInfo[]
			}
			state.status = 'success';
		})
		builder.addCase(addInfoToDevice.rejected, (state) => {
      console.log("Ошибка добавления устройства");
      // state.books = initialState.books
      state.status = "error";
		})
  },
})

// Action creators are generated for each case reducer function
export const materialsActions: any = {
  ...materialsSlice.actions,
	getAllBooks,
	createBook,
	deleteBook,
	editBook,
	createBookContent,
	deleteBookContent,
	deleteBookFile,
	addBookToDevice,
	getAllVideo,
	createVideo,
	deleteVideo,
	editVideo,
	createVideoContent,
	deleteVideoContent,
	deleteVideoFile,
	addVideoToDevice,
	getAllInfo,
	createInfo,
	deleteInfo,
	editInfo,
	createInfoContent,
	deleteInfoContent,
	deleteInfoFile,
	addInfoToDevice,
}

export default materialsSlice.reducer
