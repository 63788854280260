import { IDevice } from '../../types/deviceAndClients.types'
import { IQuery } from '../../types/query'
import { $api } from '../api'


export default class DeviceService {
	static async getAllDevices(query?: IQuery): Promise<{ data: {devices: IDevice[], count: number} }> {
		const params = `?limit=${query?.limit ? query.limit : 0}&offset=${query?.offset ? query.offset : 0}${query?.search ? `&search=${query?.search}`: ''}`
    return $api.get(`/device/${params}`)
  }
	static async createDevices(data: IDevice): Promise<{ data: IDevice }> {
    return $api.post(`/device/`, data, {headers: { "Content-Type": "multipart/form-data" }})
  }
	static async updateDevices(data: IDevice): Promise<{ data: IDevice }> {
    return $api.put(`/device/`, data, {headers: { "Content-Type": "multipart/form-data" }})
  }
	static async deleteDevices(id: number): Promise<any> {
    return $api.delete(`/device/${id}`)
  }
	static async getDeviceById(id: number): Promise<{ data: IDevice }> {
    return $api.get(`/device/id/${id}`)
  }
	static async getDeviceByUuid(uuid: number): Promise<{ data: IDevice }> {
    return $api.get(`/device/${uuid}`)
	}
	static async deleteDeviceLogo(data: {deviceId: number, file: string}): Promise<{data: IDevice}> {
    return $api.delete(`/device/logo/${data.deviceId}`, {data})
	}
}
