
import { IMaterialToDevice, IVideo, IVideoContent } from '../../types/material.types'
import { IQuery } from '../../types/query'
import { $api } from '../api'


export default class VideoService {
	//=============BOOKS=======================
	static async getAllVideo(query?: IQuery): Promise<{ data: { data: IVideo[]; count: number } }> {
		const params = `?limit=${query?.limit ? query.limit : 0}&offset=${query?.offset ? query.offset : 0}${query?.search ? `&search=${query?.search}`: ''}`
    return $api.get(`/video/${params}`)
  }
  static async createVideo(data: IVideo): Promise<{data: IVideo}> {
    return $api.post('/video', data, {headers: { "Content-Type": "multipart/form-data" }})
  }
  static async deleteVideo(id: number): Promise<{data: any}> {
    return $api.delete(`/video/${id}`)
  }
  static async editVideo(data: IVideo): Promise<{data: IVideo}> {
    return $api.put(`/video/`, data, {headers: { "Content-Type": "multipart/form-data" }})
	}
	//===============Contents=====================
  static async createVideoContent(data: IVideoContent): Promise<{data: IVideo}> {
    return $api.post(`/video/content`, data)
  }
  static async deleteVideoContent(id: number): Promise<{data: IVideo}> {
    return $api.delete(`/video/content/${id}`)
	}
	//==============file=====================
	static async deleteVideoFile(data: {videoId: number, file: string}): Promise<{data: IVideo}> {
    return $api.delete(`/video/file/${data.videoId}`, {data})
	}
	//==============Device=====================
	static async addVideoToDevice(data: IMaterialToDevice): Promise<{data: IVideo}> {
    return $api.post(`/video/device`, data)
	}
}
